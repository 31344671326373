

	import { PropType } from 'vue';
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';

	import { Actions } from '@/modules/projects/store/types/StoreTypes';
	import { Project, ThirdParty } from '@/modules/projects/types/entities';

	const projects = namespace('projects');

	@Component({
		components: {
			'confirm-dialog': () => import(/* webpackChunkName: "confirmDialog" */ '@/core/components/confirm-dialog/confirm-dialog.component.vue'),
		}
	})
	export default class ProjectThirdPartiesListComponent extends Vue {
		@projects.Action(Actions.TOGGLE_ASSOCIATION_EXISTING_SUPPLIER_TO_THIRD_PARTY) toggleAssociationExistingSupplierToThirdParty!: (payload: { projectId: number, thirdPartyPivotId: number, supplierTypeId: number, supplierId: number | null }) => Promise<void>;
		@projects.Action(Actions.TOGGLE_THIRD_PARTY_ASSIGNMENT) toggleThirdPartyAssignment!: (payload: { projectId: number, thirdPartyTypeId: number, thirdParty: ThirdParty }) => void;
		@projects.Action(Actions.REMOVE_THIRD_PARTY) removeThirdParty!: (payload: { projectId: number, pivotId: number }) => void;

		@Prop({ type: Object as PropType<Project>, required: true }) project!: Project;

		state = {
			removeThirdParty: false
		};

		content: {
			removeThirdParty: ThirdParty
		} = {
			removeThirdParty: {} as ThirdParty
		};

		resetContent(): void {
			this.content = {
				removeThirdParty: {} as ThirdParty
			};
		}

		displayThirdPartiesConfirmDialog(thirdParty: ThirdParty): boolean {
			return this.state.removeThirdParty
				&& this.content.removeThirdParty.pivotId === thirdParty.pivotId;
		}

		onRemoveThirdParty() {
			if (this.content.removeThirdParty.pivotId !== 0) {
				this.removeThirdParty({
					projectId: this.project.id!,
					pivotId: this.content.removeThirdParty.pivotId
				});
			}
		}

		onAttachedThirdPartyRemove(thirdParty: ThirdParty) {
			this.resetContent();

			if (this.state.removeThirdParty && this.content.removeThirdParty.supplierTypeId === thirdParty.supplierTypeId) {
				this.state.removeThirdParty = false;
			} else {
				this.state.removeThirdParty = true;
				this.content.removeThirdParty = thirdParty;
			}
		}

		onDetachSupplierToThirdParty(thirdParty: ThirdParty) {
			this.toggleAssociationExistingSupplierToThirdParty({
				projectId: this.project.id!,
				thirdPartyPivotId: thirdParty.pivotId,
				supplierTypeId: thirdParty.supplierTypeId,
				supplierId: null
			});
		}

		onThirdPartyAssignmentToggle(thirdParty: ThirdParty, isChanging: string) {
			let thirdPartyCopy: ThirdParty = JSON.parse(JSON.stringify(thirdParty));
			switch (isChanging) {
				case 'thirdParty.none':
					thirdPartyCopy.thirdPartyTempAssigned = 0;
					thirdPartyCopy.thirdPartyPermAssigned = 0;
					break;
				case 'thirdParty.temp':
					thirdPartyCopy.thirdPartyTempAssigned = 1;
					thirdPartyCopy.thirdPartyPermAssigned = 0;
					break;
				case 'thirdParty.perm':
					thirdPartyCopy.thirdPartyTempAssigned = 0;
					thirdPartyCopy.thirdPartyPermAssigned = 1;
					break;
				case 'supplier.none':
					thirdPartyCopy.supplierTempAssigned = 0;
					thirdPartyCopy.supplierPermAssigned = 0;
					break;
				case 'supplier.temp':
					thirdPartyCopy.supplierTempAssigned = 1;
					thirdPartyCopy.supplierPermAssigned = 0;
					break;
				case 'supplier.perm':
					thirdPartyCopy.supplierTempAssigned = 0;
					thirdPartyCopy.supplierPermAssigned = 1;
					break;
			}
			this.toggleThirdPartyAssignment({
				projectId: this.project.id!,
				thirdPartyTypeId: thirdPartyCopy.supplierTypeId,
				thirdParty: thirdPartyCopy
			});
		}
	}
